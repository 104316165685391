import { Fragment } from 'react'
import PropTypes from 'prop-types'

const ProgressBar = ({ per }) => {
  return (
    <Fragment>
      <h5>Progreso {per}%</h5>
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${per}%` }}
        ></div>
      </div>
    </Fragment>
  )
}

ProgressBar.propTypes = {
  per: PropTypes.number.isRequired,
}

export default ProgressBar
