import { destroyStorage } from "./shared/functions/services";
const Response = ({ flgEnvioOk }) => {
  const cerrarVentana = () => {  
    if(destroyStorage()){
      window.location.href = "/";
  
    } 
    
  };
  
 
  

  return (
    <div className="form-floating">
      {flgEnvioOk ? (
        <>
          <div
            className="alert alert-success d-flex align-items-center"
            role="alert"
          >
            <div>✔️ Su reporte ha sido enviado con éxito.</div>
          </div>
          <button
            type="button"
            onClick={cerrarVentana}
            className="btn btn-link"
          >
            Ir A Inicio
          </button>
        </>
      ) : (
        <>
          <div
            className="alert alert-danger d-flex align-items-center"
            role="alert"
          >
            <div>❌ Falló el envío del reporte, intente más tarde.</div>
          </div>{" "}
          <button
            type="button"
            onClick={cerrarVentana}
            className="btn btn-link"
          >
            Ir A Inicio
          </button>
        </>
      )}
    </div>
  );
};

export default Response;
