import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";

const MensajesAlerta = ({ msg, rol }) => {
  const [tipo, settipo] = useState("info");
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  useEffect(() => {
    setVisible(true);
  }, [msg]);

  useEffect(() => {
    switch (rol) {
      case "info":
        settipo("info");
        break;
      case "warning":
        settipo("warning");
        break;
      case "success":
        settipo("success");
        break;
      case "error":
        settipo("danger");
        break;
      default:
        settipo("info");
        break;
    }
  }, [rol]);
  return (
    <Fragment>
      <Alert color={tipo} isOpen={visible} toggle={onDismiss}>
        <strong> {msg}</strong>
      </Alert>
    </Fragment>
  );
};

MensajesAlerta.propTypes = {
  msg: PropTypes.string.isRequired,
  rol: PropTypes.string
};

export default MensajesAlerta;
