import { useState, useEffect, useCallback } from "react";
import { singIn } from "./shared/functions/services";
import SimmaLogo from "../pages/shared/images/simmalogo.png";
import Responsible from "./Responsible";
const Login = ({ setisAuth,menuT,queryParams }) => {
  const [user, setuser] = useState("");
  const [passw, setpassw] = useState("");
  const [flgButton, setflgButton] = useState(false);
  const [textoError, settextoError] = useState("");

  const [menuTexto, setmenuTexto] = useState("")
  const [parametros, setparametros] = useState({})

  useEffect(() => {
   // console.log({...queryParams})
    if(menuT!==""){
      setmenuTexto(menuT)
      setparametros({...queryParams})
    }
  }, [menuT, queryParams])
  

  useEffect(() => {
    if (user.length > 0 && passw.length > 0) {
      setflgButton(true);
    } else {
      setflgButton(false);
    }
  }, [user, passw]);

  const handleSubmit = useCallback(async () => {
    let sI = await singIn(user, passw);
    if (sI.success) {
      setisAuth(true);
    } else {
      setisAuth(false);
      settextoError(sI.message);
    }
  }, [user, passw, setisAuth]);

  return (
    
    <>
    {menuTexto!==""?<><Responsible menuTexto={menuTexto} parametros={parametros}  /></>:<>   <h6 className="mb-3 fw-normal titulo">
        Por favor ingrese sus credenciales
      </h6>

      <div className="form-floating">
        <input
          type="email"
          className="form-control"
          id="floatingInput"
          placeholder="email"
          onChange={(e) => setuser(e.target.value)}
        />
        <label htmlFor="floatingInput">Email</label>
      </div>
      <div className="form-floating">
        <input
          type="password"
          className="form-control"
          id="floatingPassword"
          placeholder="Contraseña"
          onChange={(e) => setpassw(e.target.value)}
        />
        <label htmlFor="floatingPassword">Contraseña</label>
      </div>
      <small>{textoError}</small>

      {flgButton ? (
        <button
          className="w-100 btn btn-lg botonIngesar"
          onClick={handleSubmit}
          type="button"
        >
          Ingresar
        </button>
      ) : (
        <button
          className="w-100 btn btn-lg botonIngesar"
          disabled
          type="button"
        >
          Ingresar
        </button>
      )}
      <div className="form-floating mt-4">
        <img alt="simma logo" style={{ width: "50%" }} src={SimmaLogo} />
      </div></>}
   
    </>
  );
};

export default Login;
