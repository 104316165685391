import { useState, useEffect, useCallback } from "react";
import {
  putReportebyToken,
  putReportebyTokenA,
  putReportebyTokenB,
  validReporte,
  validReporteA,
  destroyStorage,
  getCatText,
  convertirFecha
} from "./shared/functions/services";
const Responsible = ({ menuTexto, parametros }) => {
  const [menu, setmenu] = useState(menuTexto);
  const [param] = useState({ ...parametros });
  const [dataResponsible, setdataResponsible] = useState({});
  const [responsables, setresponsables] = useState({
    consulted: "",
    accountable: "",
    responsable: "",
    informed: ""
  });
  const [flgEnviando, setflgEnviando] = useState(false);
  const [textoCat, settextoCat] = useState("");
  const [flgEnvioOk, setflgEnvioOk] = useState(false);
  const [flgResponsables, setflgResponsables] = useState(false);
  const [flgApprove, setflgApprove] = useState(false);
  const [flgPlazo, setflgPlazo] = useState(false);
  const [fechaPosponer, setfechaPosponer] = useState("");
  const [flgbtnAplazamiento, setflgbtnAplazamiento] = useState(false);
  const [textoEnviando, settextoEnviando] = useState("");
  const [textoEnvioOk, settextoEnvioOk] = useState("");

  /*Inicio */

  useEffect(() => {
    switch (menu) {
      case "responsible":
        try {
          const uD = Promise.all([validReporte(param.t)]);
          uD.then((res) => {
            if (res[0].success) {
              settextoEnviando("Actualizando Responsables...");
              settextoEnvioOk(
                " Se han actualizado los responsables y además se les ha notificado su asignación a todo el comité de seguridad."
              );

              setdataResponsible({ ...res[0].data });

              settextoCat(getCatText(res[0].data.reporte[0].nivelCat));
            } else {
              switch (res[0].message) {
                case "ResponsablesOK":
                  setmenu("ResponsablesOK");
                  break;
                case "Token Expired":
                  setmenu("Token Expired");
                  break;
                case "No Token Provided":
                  setmenu("No Token Provided");
                  break;
                default:
                  break;
              }
            }
          }).catch((err) => {
            console.log(err);
          });
        } catch (e) {
          console.log(e);
        }
        break;
      case "postponement":
        try {
          const uD = Promise.all([validReporteA(param.t)]);
          uD.then((res) => {
            if (res[0].success) {
              settextoEnviando("Solicitando Aplazamiento...");
              settextoEnvioOk(
                "Se ha enviado la solicitud, se te avisará por correo si esta fue aceptada o denegada."
              );
              setdataResponsible({ ...res[0].data });
              console.log(res[0].data);
              settextoCat(getCatText(res[0].data.reporte[0].nivelCat));
            } else {
              switch (res[0].message) {
                case "ResponsablesFalse":
                  setmenu("ResponsablesFalse");
                  break;
                case "Token Expired":
                  setmenu("Token Expired");
                  break;
                case "No Token Provided":
                  setmenu("No Token Provided");
                  break;
                default:
                  break;
              }
            }
          }).catch((err) => {
            console.log(err);
          });
        } catch (e) {
          console.log(e);
        }
        break;
      case "approval":
        try {
          const uD = Promise.all([validReporteA(param.t)]);
          uD.then((res) => {
            if (res[0].success) {
              settextoEnviando("Enviando Respuesta...");
              settextoEnvioOk("Se ha enviado la respuesta al aplazamiento.");
              setdataResponsible({ ...res[0].data });
              //console.log(res[0].data)
              settextoCat(getCatText(res[0].data.reporte[0].nivelCat));
              switch (res[0].data.reporte[0].descripcion[0].flgPostponement) {
                case "pending":
                  setflgApprove(true);
                  break;
                case "approved":
                  setflgApprove(false);
                  setmenu("Approved");
                  break;
                case "denied":
                  setflgApprove(false);
                  setmenu("Denied");
                  break;
                default:
                  break;
              }
            } else {
              switch (res[0].message) {
                case "ResponsablesFalse":
                  setmenu("ResponsablesFalse");
                  break;
                case "Token Expired":
                  setmenu("Token Expired");
                  break;
                case "No Token Provided":
                  setmenu("No Token Provided");
                  break;
                default:
                  break;
              }
            }
          }).catch((err) => {
            console.log(err);
          });
        } catch (e) {
          console.log(e);
        }
        break;
      default:
        break;
    }
  }, [menu, param]);

  /*
  Responsables
  */

  const asignarResponsables = useCallback(
    (e) => {
      let rrr = { ...responsables };
      rrr[e.target.name] = e.target.value;
      setresponsables({ ...rrr });
    },
    [responsables]
  );

  const cargarReponsables = useCallback(() => {
    let report = { ...dataResponsible.reporte[0].descripcion[0] };
    report.responsables = { ...responsables };
    report.flgResponsables = true;

    setflgEnviando(true);
    const uR = Promise.all([putReportebyToken(param.t, report)]);
    uR.then((res) => {
      //console.log(res[0]);
      if (res[0].success) {
        console.log(res[0]);
        setflgEnviando(false);
        setflgEnvioOk(true);
      } else {
        //console.log("error en post Reporte 1")

        setflgEnviando(false);
        setflgEnvioOk(false);
      }
    }).catch((err) => {
      console.log(err);
      setflgEnviando(false);
      setflgEnvioOk(false);
    });
  }, [responsables, dataResponsible, param]);

  useEffect(() => {
    if (fechaPosponer !== "") {
      setflgbtnAplazamiento(true);
    } else {
      setflgbtnAplazamiento(false);
    }
  }, [fechaPosponer]);

  useEffect(() => {
    if (responsables.responsable !== "") {
      setflgResponsables(true);
    } else {
      setflgResponsables(false);
    }
  }, [responsables]);
  const closeWindow = () => {
    if (destroyStorage()) {
      window.location.href = "/";
    }
  };

  /*
 Aplazamiento
 */
  useEffect(() => {
    if (!flgPlazo) {
      setfechaPosponer("");
    }
  }, [flgPlazo]);

  const cargarAplazamiento = useCallback(() => {
    let report = { ...dataResponsible.reporte[0].descripcion[0] };
    report.fechaPostponement = fechaPosponer;
    report.flgPostponement = "pending";
    report.idResponsable = dataResponsible.idResponsable;

    setflgEnviando(true);
    const uR = Promise.all([putReportebyTokenA(param.t, report)]);
    uR.then((res) => {
      //console.log(res[0]);
      if (res[0].success) {
        setflgEnviando(false);
        setflgEnvioOk(true);
      } else {
        //console.log("error en post Reporte 1")

        setflgEnviando(false);
        setflgEnvioOk(false);
      }
    }).catch((err) => {
      console.log(err);
      setflgEnviando(false);
      setflgEnvioOk(false);
    });
  }, [dataResponsible, fechaPosponer, param]);

  /*
Aprobar Plazo
*/
  const respuestaAplazamiento = useCallback(
    (e) => {
      let report = { ...dataResponsible.reporte[0].descripcion[0] };
      report.flgPostponement = e;
      console.log(report);

      setflgEnviando(true);
      const uR = Promise.all([putReportebyTokenB(param.t, report)]);
      uR.then((res) => {
        //console.log(res[0]);
        if (res[0].success) {
          setflgEnviando(false);
          setflgEnvioOk(true);
        } else {
          //console.log("error en post Reporte 1")

          setflgEnviando(false);
          setflgEnvioOk(false);
        }
      }).catch((err) => {
        console.log(err);
        setflgEnviando(false);
        setflgEnvioOk(false);
      });
    },
    [dataResponsible, param]
  );

  /*
Sacar las vistas
*/
  return (
    <>
      {menu === "postponement" && (
        <div className="row">
          {" "}
          {dataResponsible.reporte && (
            <>
              {!flgEnviando && !flgEnvioOk && (
                <>
                  {" "}
                  <div className="col-12">
                    <table
                      className="table table-bordered"
                      style={{ color: "white" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col" colSpan="2">
                            {`REPORTE ${dataResponsible.reporte[0].nivelReporte}`}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Fecha:</th>
                          <td colSpan="3">
                            {dataResponsible.reporte[0].fechaReporte}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Reporte:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion[0]
                                .textoAlerta
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Ubicación:</th>
                          <td colSpan="3">
                            {dataResponsible.reporte[0].idUbicacion}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Nivel:</th>
                          <td colSpan="3">
                            {dataResponsible.reporte[0].nivelReporte}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">Nivel CAT:</th>
                          <td colSpan="3">{textoCat}</td>
                        </tr>

                        <tr>
                          <th scope="row">Descripción:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion[0]
                                .descripcion
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-12">
                    <div className="alert alert-warning" role="alert">
                      {dataResponsible.reporte[0].descripcion[0].textoPlazo}
                    </div>
                  </div>
                  <div className="col-12">
                    <table
                      className="table table-bordered"
                      style={{ color: "white" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col" colSpan="2">
                            {`RESPONSABLES`}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Consulted:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion
                                .responsables.consulted
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Accountable:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion
                                .responsables.accountable
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Responsable:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion
                                .responsables.responsable
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Informed:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion
                                .responsables.informed
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-12">
                      <button
                        type="button"
                        className="w-100 btn btn-lg botonIngesar mb-2"
                        onClick={() => {
                          setflgPlazo(!flgPlazo);
                        }}
                      >
                        📅 Solicitar Aplazamiento
                      </button>
                    </div>
                  </div>
                  {flgPlazo && (
                    <div className="form-row">
                      <div className="form-group col-12">
                        <input
                          type="date"
                          onChange={(e) => {
                            setfechaPosponer(e.target.value);
                          }}
                          className="form-control"
                        />
                        <label htmlFor="floatingTextarea">
                          Ingrese el día máximo que desea de plazo para cerrar
                          el reporte.
                        </label>
                        {flgbtnAplazamiento ? (
                          <button
                            type="button"
                            onClick={() => {
                              cargarAplazamiento();
                            }}
                            className="w-100 btn btn-lg botonIngesar mb-2"
                          >
                            📨 Enviar Solicitud
                          </button>
                        ) : (
                          <button
                            type="button"
                            disabled
                            className="w-100 btn btn-lg botonIngesar mb-2"
                          >
                            📨 Enviar Solicitud
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}

      {menu === "approval" && (
        <div className="row">
          {dataResponsible.reporte && flgApprove && (
            <>
              {!flgEnviando && !flgEnvioOk && (
                <div className="col-12">
                  <div className="col-12">
                    <table
                      className="table table-bordered"
                      style={{ color: "white" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col" colSpan="2">
                            {`REPORTE ${dataResponsible.reporte[0].nivelReporte}`}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Fecha:</th>
                          <td colSpan="3">
                            {dataResponsible.reporte[0].fechaReporte}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Reporte:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion[0]
                                .textoAlerta
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Ubicación:</th>
                          <td colSpan="3">
                            {dataResponsible.reporte[0].idUbicacion}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Nivel:</th>
                          <td colSpan="3">
                            {dataResponsible.reporte[0].nivelReporte}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">Nivel CAT:</th>
                          <td colSpan="3">{textoCat}</td>
                        </tr>

                        <tr>
                          <th scope="row">Descripción:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion[0]
                                .descripcion
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-12">
                    <div className="alert alert-info" role="alert">
                      {`Fecha solicitada de cierre: ${convertirFecha(
                        dataResponsible.reporte[0].descripcion[0]
                          .fechaPostponement
                      )}`}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="button"
                          onClick={() => {
                            respuestaAplazamiento("approved");
                          }}
                          className="w-100 btn btn-lg botonIngesar mb-2"
                        >
                          Aprobar Nuevo Plazo De Cierre
                        </button>
                      </div>

                      <div className="col-6">
                        <button
                          type="button"
                          onClick={() => {
                            respuestaAplazamiento("denied");
                          }}
                          className="w-100 btn btn-lg btn-secondary mb-2"
                        >
                          Denegar Nuevo Plazo De Cierre
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <table
                      className="table table-bordered"
                      style={{ color: "white" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col" colSpan="2">
                            {`RESPONSABLES`}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Consulted:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion
                                .responsables.consulted
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Accountable:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion
                                .responsables.accountable
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Responsable:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion
                                .responsables.responsable
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Informed:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion
                                .responsables.informed
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}

      {menu === "responsible" && (
        <div className="row">
          {dataResponsible.reporte && dataResponsible.listaComite && (
            <>
              {!flgEnviando && !flgEnvioOk && (
                <>
                  {" "}
                  <div className="col-12">
                    <table
                      className="table table-bordered"
                      style={{ color: "white" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col" colSpan="2">
                            {`REPORTE ${dataResponsible.reporte[0].nivelReporte}`}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Fecha:</th>
                          <td colSpan="3">
                            {dataResponsible.reporte[0].fechaReporte}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Reporte:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion[0]
                                .textoAlerta
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Ubicación:</th>
                          <td colSpan="3">
                            {dataResponsible.reporte[0].idUbicacion}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Nivel:</th>
                          <td colSpan="3">
                            {dataResponsible.reporte[0].nivelReporte}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">Nivel CAT:</th>
                          <td colSpan="3">{textoCat}</td>
                        </tr>

                        <tr>
                          <th scope="row">Descripción:</th>
                          <td colSpan="3">
                            {
                              dataResponsible.reporte[0].descripcion[0]
                                .descripcion
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-12">
                    <div className="alert alert-warning" role="alert">
                      {dataResponsible.reporte[0].descripcion[0].textoPlazo}
                    </div>
                  </div>
                  <div className="col-12">
                    <h3>Asignar Miembros De Comité</h3>

                    <div className="form-row">
                      <div className="form-group col-12">
                        <label htmlFor="inputState">Asignar Consulted</label>
                        <select
                          id="inputState"
                          onChange={(e) => asignarResponsables(e)}
                          name="consulted"
                          className="form-control"
                        >
                          <option value="" defaultValue>
                            Elegir...
                          </option>
                          {dataResponsible.listaComite.map((x) => {
                            return (
                              <option
                                key={x._id}
                                value={x._id}
                              >{`${x.name} ${x.last_name} - ${x.username}`}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-12">
                        <label htmlFor="inputState2">Asignar Accountable</label>
                        <select
                          id="inputState2"
                          onChange={(e) => asignarResponsables(e)}
                          name="accountable"
                          className="form-control"
                        >
                          <option value="" defaultValue>
                            Elegir...
                          </option>
                          {dataResponsible.listaComite.map((x) => {
                            return (
                              <option
                                key={x._id}
                                value={x._id}
                              >{`${x.name} ${x.last_name} - ${x.username}`}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-12">
                        <label htmlFor="inputState3">
                          Asignar Responsable
                        </label>
                        <select
                          id="inputState3"
                          onChange={(e) => asignarResponsables(e)}
                          name="responsable"
                          className="form-control"
                        >
                          <option value="" defaultValue>
                            Elegir...
                          </option>
                          {dataResponsible.listaComite.map((x) => {
                            return (
                              <option
                                key={x._id}
                                value={x._id}
                              >{`${x.name} ${x.last_name} - ${x.username}`}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-12">
                        <label htmlFor="inputState4">Asignar Informed</label>
                        <select
                          id="inputState4"
                          onChange={(e) => asignarResponsables(e)}
                          name="informed"
                          className="form-control"
                        >
                          <option value="" defaultValue>
                            Elegir...
                          </option>
                          {dataResponsible.listaComite.map((x) => {
                            return (
                              <option
                                key={x._id}
                                value={x._id}
                              >{`${x.name} ${x.last_name} - ${x.username}`}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-12 mt-2">
                        {flgResponsables ? (
                          <button
                            type="button"
                            className="w-100 btn btn-lg botonIngesar"
                            onClick={cargarReponsables}
                          >
                            Establecer Responsables
                          </button>
                        ) : (
                          <button
                            disabled
                            type="button"
                            className="w-100 btn btn-lg botonIngesar"
                          >
                            Establecer Responsables
                          </button>
                        )}
                      </div>{" "}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
      {menu === "ResponsablesOK" && (
        <>
          <div className="alert alert-info" role="alert">
            Los responsables ya han sido asignados para este reporte.
          </div>{" "}
          <button type="button" onClick={closeWindow} className="btn btn-link">
            Salir
          </button>
        </>
      )}
      {menu === "ResponsablesFalse" && (
        <>
          <div className="alert alert-info" role="alert">
            Los responsables no han sido asignados para este reporte.
          </div>{" "}
          <button type="button" onClick={closeWindow} className="btn btn-link">
            Salir
          </button>
        </>
      )}
      {menu === "Token Expired" && (
        <>
          {" "}
          <div className="alert alert-danger" role="alert">
            El tiempo para asignar responsables para este reporte ya expiró.
          </div>{" "}
          <button
            type="button"
            onClick={() => closeWindow()}
            className="btn btn-link"
          >
            Salir
          </button>
        </>
      )}
      {menu === "No Token Provided" && (
        <>
          <div className="alert alert-danger" role="alert">
            Error, no tiene el nivel para accerder a este sitio.
          </div>
          <button type="button" onClick={closeWindow} className="btn btn-link">
            Salir
          </button>
        </>
      )}

      {menu === "Approved" && (
        <>
          <div className="alert alert-info" role="alert">
            Este aplazamiento ya ha sido aprobado.
          </div>
          <button type="button" onClick={closeWindow} className="btn btn-link">
            Salir
          </button>
        </>
      )}

      {menu === "Denied" && (
        <>
          <div className="alert alert-warning" role="alert">
            Este aplazamiento ha sido negado.
          </div>
          <button type="button" onClick={closeWindow} className="btn btn-link">
            Salir
          </button>
        </>
      )}

      {flgEnviando && (
        <div className="col-12">
          <div className="d-flex float-left">
            <strong>{textoEnviando} </strong>
            <div
              className="spinner-border float-right"
              role="status"
              aria-hidden="true"
            ></div>
          </div>
        </div>
      )}

      {flgEnvioOk && (
        <div className="col-12">
          <div className="alert alert-success" role="alert">
            {textoEnvioOk}
          </div>
          <button type="button" onClick={closeWindow} className="btn btn-link">
            Salir
          </button>
        </div>
      )}
    </>
  );
};

export default Responsible;
