import axios from "axios";
/*
 * Constantes*/

const KEY_PROYECTO = process.env.REACT_APP_PROYECTO;
const URL_API = process.env.REACT_APP_URL_MACHOTE;
const URL_STATIC = process.env.REACT_APP_URL_STATIC;
/*
 *Funciones Auth */
export function getFromStorage(key) {
  if (!key) {
    return null;
  }
  try {
    const valueStr = localStorage.getItem(key);
    if (valueStr) {
      return JSON.parse(valueStr);
    }
    return null;
  } catch (err) {
    return null;
  }
}
export function setInStorage(key, obj) {
  if (!key) {
    console.error("Error: Key is missing");
  }
  try {
    localStorage.setItem(key, JSON.stringify(obj));
  } catch (err) {
    console.error(err);
  }
}
export function isAutenticado() {
  const obj = getFromStorage("the_main_app_" + KEY_PROYECTO);
  if (obj && obj.token) {
    return true;
  } else {
    return false;
  }
}

export function destroyStorage() {
  try {
    localStorage.removeItem("tipo_" + KEY_PROYECTO);
    localStorage.removeItem("nombre_" + KEY_PROYECTO);
    localStorage.removeItem("the_main_app_" + KEY_PROYECTO);

    return true;
  } catch (err) {
    return false;
  }
}

/*
 *Funciones Login */

//función sing in
export async function singIn(email, password) {
  return fetch(URL_API + "user/signin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      username: email,
      password: password
    })
  })
    .then((res) => res.json())
    .then((data) => {
      //console.log(data);
      if (data.success) {
        setInStorage("the_main_app_" + KEY_PROYECTO, {
          token: data.token
        });
        setInStorage("tipo_" + KEY_PROYECTO, {
          tipo: data.tipo
        });
        setInStorage("nombre_" + KEY_PROYECTO, {
          nombre: data.name
        });
        return { success: data.success };
      } else {
        return { success: data.success, message: data.message };
      }
    });
}

/*
 *Funciones formulario */

//pedir ubicaciones y tipos de reportes

export async function getUbicaciones() {
  return fetch(URL_API + "seguridad/parasistema/presas", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "an-access-token":
        "Bearer " + getFromStorage("the_main_app_" + KEY_PROYECTO).token
    }
  })
    .then((res) => res.json())
    .then((data) => {
      //console.log(data);
      return data;
    });
}

export async function getReportesAbiertos() {
  return fetch(URL_API + "seguridad/parasistema/abierto/presas", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "an-access-token":
        "Bearer " + getFromStorage("the_main_app_" + KEY_PROYECTO).token
    }
  })
    .then((res) => res.json())
    .then((data) => {
      //console.log(data);
      return data;
    });
}

export async function getReporte(id) {
  return fetch(URL_API + "seguridad/reporte/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "an-access-token":
        "Bearer " + getFromStorage("the_main_app_" + KEY_PROYECTO).token
    }
  })
    .then((res) => res.json())
    .then((data) => {
      //console.log(data);
      return data;
    });
}

export async function postReporte(
  ubicacion,
  tipo,
  descripcion,
  nivelReporte,
  sitioOtro,
  incidenteOtro,
  textoPlazo,
  textoAlerta,
  fechaIncidente,
  fileList,
  nivelCat
) {
  const tok = getFromStorage("the_main_app_" + KEY_PROYECTO).token;
  return fetch(URL_API + "seguridad/reporte", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "an-access-token": "Bearer " + tok
    },
    body: JSON.stringify({
      idUbicacion: ubicacion,
      idTipoReporte: tipo,
      nivelReporte: nivelReporte,
      fechaReporte: fechaIncidente,
      nivelCat: nivelCat,
      isClose: false,
      Zona: "presas",
      descripcion: [
        {
          descripcion: descripcion,
          ubicacion: sitioOtro,
          tipoReporte: incidenteOtro,
          fileList: fileList,
          textoPlazo: textoPlazo,
          textoAlerta: textoAlerta
        }
      ]
    })
  })
    .then((res) => res.json())
    .then((data) => {
      //console.log(data)
      return data;
    });
}

//* Modificar reporte*/
export async function putReporte(id, descr) {
  const tok = getFromStorage("the_main_app_" + KEY_PROYECTO).token;
  return fetch(URL_API + "seguridad/reporte/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "an-access-token": "Bearer " + tok
    },
    body: JSON.stringify({
      isClose: true,
      descripcion: [{ ...descr }]
    })
  })
    .then((res) => res.json())
    .then((data) => {
      //console.log(data)
      return data;
    });
}
//*subir archivo*//
export async function subirArchivo(file, setuploadPer) {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const tok = getFromStorage("the_main_app_" + KEY_PROYECTO).token;
    const res = await axios.post(
      URL_STATIC + "/api/u/files/savefile",
      formData,
      {
        timeout: 1000 * 300,
        headers: {
          "Content-Type": "multipart/form-data",
          "an-access-token": "Bearer " + tok
        },
        onUploadProgress: (ProgressEvent) => {
          setuploadPer(
            parseInt(
              Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
            )
          );
          //setTimeout(() => setuploadPer(0), 10000);
        }
      }
    );

    if (res.data.success) {
      return {
        success: true,
        data: res.data,
        msg: `El Archivo ${res.data.fileName} Se Ha Cargado Correctamente`
      };
    } else {
      return {
        success: false,
        msg: `Hubo un error al tratar del subir el archivo`
      };
    }
  } catch (err) {
    console.log(err);
    if (err.response.status === 500) {
      return {
        success: false,
        msg: `Hubo Un Problema En El Backend.`
      };
    } else {
      //en caso de error porque el archivo estaba mal o se pudo procesar aquí que salga el error
      return {
        success: false,
        msg: err.response.data.msg
      };
    }
  }
}

export const obtenerParametrosDeConsulta = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const params = {};

  for (const [key, value] of urlParams.entries()) {
    params[key] = value;
  }

  return params;
};

export async function validReporte(idT) {
  return fetch(URL_API + "seguridad/segreporte/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "an-access-token": "Bearer " + idT
    }
  })
    .then((res) => res.json())
    .then((data) => {
      //console.log(data);
      return data;
    });
}

/**Modificar Reporte by token */
export async function putReportebyToken(idT, descr) {
  return fetch(URL_API + "seguridad/segreporte/", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "an-access-token": "Bearer " + idT
    },
    body: JSON.stringify({
      descripcion: [{ ...descr }]
    })
  })
    .then((res) => res.json())
    .then((data) => {
      //console.log(data)
      return data;
    });
}

export function getCatText(n) {
  switch (n) {
    case "cat0":
      return "Cat 0 - Cuasi-accidente";

    case "cat1":
      return "Cat 1 - Insignificante";

    case "cat2":
      return "Cat 2 - Menor";

    case "cat3":
      return "Cat 3 - Moderada";

    case "cat4":
      return "Cat 4 - Mayor";

    case "cat5":
      return "Cat 0 - Catastrófica";

    default:
      return "";
  }
}

/*Aplazar */

export async function validReporteA(idT) {
  return fetch(URL_API + "seguridad/segreport2postponement/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "an-access-token": "Bearer " + idT
    }
  })
    .then((res) => res.json())
    .then((data) => {
      //console.log(data);
      return data;
    });
}

export async function putReportebyTokenA(idT, descr) {
  return fetch(URL_API + "seguridad/segreport2postponement/", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "an-access-token": "Bearer " + idT
    },
    body: JSON.stringify({
      descripcion: [{ ...descr }]
    })
  })
    .then((res) => res.json())
    .then((data) => {
      //console.log(data)
      return data;
    });
}

export async function putReportebyTokenB(idT, descr) {
  return fetch(URL_API + "seguridad/segreport2approval/", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "an-access-token": "Bearer " + idT
    },
    body: JSON.stringify({
      descripcion: [{ ...descr }]
    })
  })
    .then((res) => res.json())
    .then((data) => {
      //console.log(data)
      return data;
    });
}

export function convertirFecha(fecha) {
  // Dividir la fecha en año, mes y día
  var partes = fecha.split("-");
  var anio = partes[0];
  var mes = partes[1];
  var dia = partes[2];

  // Devolver la fecha en el formato deseado
  return dia + "/" + mes + "/" + anio;
}
