import { useState, useEffect, useCallback } from "react";
import Response from "./Response";
import {
  getUbicaciones,
  postReporte,
  subirArchivo
} from "./shared/functions/services";
import ProgressBar from "./shared/Components/ProgressBar";
import MessageAlert from "./shared/Components/MessageAlert";

const Formulario = ({ setVista }) => {
  //estados del formulario
  const [ubicaciones, setubicaciones] = useState([]);
  const [tipoReporte, settipoReporte] = useState([]);
  const [conteo, setconteo] = useState(0);
  const [sitioIncidente, setsitioIncidente] = useState("");
  const [tipoIncidente, settipoIncidente] = useState("");
  const [nivelIncidente, setnivelIncidente] = useState("");
  const [nivelCat, setnivelCat] = useState("");
  const [fechaIncidente, setfechaIncidente] = useState("");
  const [descripcionIncidente, setdescripcionIncidente] = useState("");
  const [sitioOtro, setsitioOtro] = useState("");
  const [incidenteOtro, setincidenteOtro] = useState("");

  //banderas
  const [flgNivelIncidente, setflgNivelIncidente] = useState([
    false,
    false,
    false
  ]);
  const [flgCat, setflgCat] = useState([
    true, true, true, true,true,true
  ])
  const [textoPlazo, settextoPlazo] = useState("");
  const [flgSelect, setflgSelect] = useState(0);
  const [textoAlerta, settextoAlerta] = useState("");
  const [flgButton, setflgButton] = useState(false);
  const [flgEnviando, setflgEnviando] = useState(false);
  const [flgAlertaOtro, setflgAlertaOtro] = useState(false);
  const [textoAlertaOtro, settextoAlertaOtro] = useState(false);
  const [flgEnvioOk, setflgEnvioOk] = useState(false);
  const [flgResponse, setflgResponse] = useState(false);
  const [flgsitioOtro, setflgsitioOtro] = useState(false);
  const [flgincidenteOtro, setflgincidenteOtro] = useState(false);
  //subir archivo
  const [uploadPer, setuploadPer] = useState(0);
  const [file, setfile] = useState("");
  const [filename, setfilename] = useState("Elija El Archivo");
  const [archivoVal, setarchivoVal] = useState(false);
  const [tipoFile, settipoFile] = useState("");
  const [message, setmessage] = useState({ msg: "", rol: "" });

  //efectos

  useEffect(() => {
    if (flgNivelIncidente[0]) {
      setflgSelect(1);
    } else if (flgNivelIncidente[1]) {
      setflgSelect(2);
    } else if (flgNivelIncidente[2]) {
      setflgSelect(3);
    } else {
      setflgSelect(0);
    }
  }, [flgNivelIncidente]);

  useEffect(() => {
    try {
      const uD = Promise.all([getUbicaciones()]);
      uD.then((res) => {
        setubicaciones(res[0].ubicaciones);
        settipoReporte(res[0].tipos);
        setconteo(Number(res[0].cont));
      }).catch((err) => {
        console.log(err);
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    //console.log(fechaIncidente)
    if (
      sitioIncidente !== "" &&
      tipoIncidente !== "" &&
      fechaIncidente !== "" &&
      nivelCat !== "" &&
      nivelIncidente !== "" &&
      descripcionIncidente !== "" &&
      textoAlerta !== "" &&
      textoAlerta !== "-"
    ) {
      setflgButton(true);
    } else {
      setflgButton(false);
    }
  }, [
    sitioIncidente,
    tipoIncidente,
    fechaIncidente,
    nivelCat,
    nivelIncidente,
    descripcionIncidente,
    textoAlerta
  ]);

  useEffect(() => {
    if (sitioIncidente === "62ed7fc87d4ef7178c912e9c") {
      setflgsitioOtro(true);
    } else {
      setflgsitioOtro(false);
    }
  }, [sitioIncidente]);

  useEffect(() => {
    if (tipoIncidente === "otro") {
      setflgincidenteOtro(true);
    } else {
      setflgincidenteOtro(false);
    }
  }, [tipoIncidente]);

  useEffect(() => {
    if (flgsitioOtro && flgincidenteOtro) {
      setflgAlertaOtro(true);
      settextoAlertaOtro(
        "Por favor ingrese el tipo de observación y la ubicación"
      );
    }

    if (flgsitioOtro && !flgincidenteOtro) {
      setflgAlertaOtro(true);
      settextoAlertaOtro("Por favor ingrese la ubicación de la observación");
    }

    if (!flgsitioOtro && flgincidenteOtro) {
      setflgAlertaOtro(true);
      settextoAlertaOtro("Por favor ingrese el tipo de observación");
    }

    if (!flgsitioOtro && !flgincidenteOtro) {
      setflgAlertaOtro(false);
      settextoAlertaOtro("");
    }
  }, [flgsitioOtro, flgincidenteOtro]);

  //funciones
  const handleChange = (e) => {
    settextoAlerta("");
    const { name } = e.target;
    switch (name) {
      case "PAISAJISTICO":
        setnivelIncidente(name);
        settextoPlazo("⚠️ Tienes 15 días para cerrar este reporte. ⚠️");
        setflgNivelIncidente([true, false, false]);
        setflgCat([false, false, false,true,true,true])
        break;
      case "FISCALIZACION":
        setnivelIncidente(name);
        settextoPlazo("⚠️ Tienes 3 días para cerrar este reporte. ⚠️");
        setflgCat([true, true, true,false,false,true])

        setflgNivelIncidente([false, true, false]);
        break;
      case "DAMSAFETY":
        setnivelIncidente(name);
        settextoPlazo("⚠️ Tienes 1 día para cerrar este reporte. ⚠️");
        setflgCat([true, true, true,true,false,false])

        setflgNivelIncidente([false, false, true]);
        break;

      default:
        setnivelIncidente("");
        settextoPlazo("");
        setflgCat([true, true, true,true,true,true])

        setflgNivelIncidente([false, false, false]);
        break;
    }
  };

  const onSelect = (e) => {
    settextoAlerta(e.target.value);
    // console.log(e.target.value);
  };
  //subir archivo

  const handleSubmit = useCallback(async () => {
    try {
      setflgEnviando(true);
      var fileList = [];
      if (archivoVal) {
        let dFile = await subirArchivo(file, setuploadPer);
        if (dFile.success) {
          fileList = [{ ...dFile.data, tipo: tipoFile }];
        }
      }

      const uR = Promise.all([
        postReporte(
          sitioIncidente,
          tipoIncidente,
          descripcionIncidente,
          nivelIncidente,
          sitioOtro,
          incidenteOtro,
          textoPlazo,
          textoAlerta,
          fechaIncidente + ":00Z",
          fileList,
          nivelCat
        )
      ]);
      uR.then((res) => {
        //console.log(res[0]);
        if (res[0].success) {
          setflgEnviando(false);
          setflgEnvioOk(true);
          setflgResponse(true);
        } else {
          //console.log("error en post Reporte 1")

          setflgEnviando(false);
          setflgEnvioOk(false);
          setflgResponse(true);
        }
      }).catch((err) => {
        console.log(err);
        setflgEnviando(false);
        setflgEnvioOk(false);
        setflgResponse(true);
      });
    } catch (err) {
      console.log(err);
    }
  }, [
    sitioIncidente,
    tipoIncidente,
    nivelIncidente,
    sitioOtro,
    incidenteOtro,
    descripcionIncidente,
    fechaIncidente,
    file,
    nivelCat,
    setuploadPer,
    tipoFile,
    archivoVal,
    textoPlazo,
    textoAlerta
  ]);

  const onChangeFile = (e) => {
    if (e.target.files[0] !== undefined) {
      setuploadPer(0);
      setfile(e.target.files[0]);
      setfilename(e.target.files[0].name);
      const exten = e.target.files[0].name.split(".");
      const extenL = exten.length;
      if (
        exten[extenL - 1] === "jpg" ||
        exten[extenL - 1] === "jpeg" ||
        exten[extenL - 1] === "JPEG" ||
        exten[extenL - 1] === "png" ||
        exten[extenL - 1] === "JPG" ||
        exten[extenL - 1] === "PNG"
      ) {
        setmessage({
          msg: "",
          rol: ""
        });
        setarchivoVal(true);
        settipoFile("img");
      } else if (exten[extenL - 1] === "pdf"||exten[extenL - 1] === "PDF") {
        setarchivoVal(true);
        settipoFile("pdf");
        setmessage({
          msg: "",
          rol: ""
        });
      } else if (exten[extenL - 1] === "zip"||exten[extenL - 1] === "ZIP") {
        setarchivoVal(true);
        settipoFile("zip");
        setmessage({
          msg: "",
          rol: ""
        });
      } else if (exten[extenL - 1] === "rar"||exten[extenL - 1] === "RAR") {
        setarchivoVal(true);
        settipoFile("rar");
        setmessage({
          msg: "",
          rol: ""
        });
      } else {
        setarchivoVal(false);
        setmessage({
          msg: "El Archivo Debe Ser pdf, PDF, rar, RAR, zip, ZIP, jpg, JPG, JPEG, jpeg, png o PNG.",
          rol: "warning"
        });
      }
    }
  };

  return !flgResponse ? (
    <>
      <button
        type="button"
        onClick={() => setVista("menu")}
        className="btn btn-link"
      >
        Regresar Al Menú
      </button>
      <h6 className="mb-3 fw-normal titulo">
        Ingrese los datos de la observación a realizar
      </h6>
      <div className="form-floating">
        <select
          className="form-control form-signin-select"
          onChange={(e) => {
            setsitioIncidente(e.target.value);
          }}
          id="sitios"
        >
          <option defaultValue={""} value="" className="item-s">
            Elija el sitio donde ocurrió la observación
          </option>

          {ubicaciones.map((ubicacion, index) => {
            return (
              <option key={index} value={ubicacion._id} className="item-s">
                {ubicacion.NombreUbicacion}
              </option>
            );
          })}
        </select>
        <label htmlFor="sitios">Donde Ocurre La Observación ☟</label>
      </div>
      {flgsitioOtro && (
        <div className="form-floating">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder="Indique Ubicación De La Observación"
            onChange={(e) => setsitioOtro(e.target.value)}
          />
          <label htmlFor="floatingInput">
            Indique Ubicación De La Observación
          </label>
        </div>
      )}
      <div className="form-floating">
        Número de Incidente a Registrar{" "}
        <span className="badge badge-dark">{`INC_${conteo}`}</span>
      </div>
      <div className="form-floating">
        <select
          className="form-control form-signin-select"
          onChange={(e) => {
            settipoIncidente(e.target.value);
          }}
          id="tipoAlerta"
        >
          <option defaultValue={""} value="" className="item-s">
            Elija el área/componente
          </option>

          {tipoReporte.map((tipo, index) => {
            return (
              <option key={index} value={tipo._id} className="item-s">
                {tipo.TipoReporte}
              </option>
            );
          })}
        </select>
        <label htmlFor="tipoAlerta">Seleccione Área/Componente</label>
      </div>
      <div className="form-floating">
        <input
          type="datetime-local"
          onChange={(e) => setfechaIncidente(e.target.value)}
          className="form-control"
        />
        <label htmlFor="floatingTextarea">
          Ingrese el día y hora del incidente
        </label>
      </div>
      {flgincidenteOtro && (
        <div className="form-floating">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder="Indique El Tipo De Observación"
            onChange={(e) => setincidenteOtro(e.target.value)}
          />
          <label htmlFor="floatingInput">Indique El Tipo De Observación</label>
        </div>
      )}
      <div className="form-floating">
        {flgAlertaOtro && (
          <div
            className="alert alert-warning d-flex align-items-center"
            role="alert"
          >
            <div>⚠️ {textoAlertaOtro}</div>
          </div>
        )}
      </div>
      <div className="form-floating ">
        <div className="form-check">
          <input
            className="form-check-input circuloNaranja"
            type="checkbox"
            checked={flgNivelIncidente[0]}
            name="PAISAJISTICO"
            onChange={(e) => handleChange(e)}
            id="flexRadioDefault0"
          />
          <label className="form-check-label " htmlFor="flexRadioDefault0">
            PAISAJISTICO
          </label>
        </div>
        {flgSelect === 1 && (
          <div className="mt-1 mb-3 form-group">
            <select onChange={(e) => onSelect(e)} className="form-control">
              <option value="-">Elija La Alerta</option>
              <option value="Temas Operativos">Temas Operativos</option>
              <option value="Orden Y Limpieza">Orden Y Limpieza</option>
              <option value="Obstrucciones De Accesos">
                Obstrucciones De Accesos
              </option>
              <option value="Capacidad De Respuesta">
                Capacidad De Respuesta
              </option>
            </select>
          </div>
        )}

        <div className="form-check">
          <input
            className="form-check-input circuloNaranja"
            type="checkbox"
            checked={flgNivelIncidente[1]}
            name="FISCALIZACION"
            onChange={(e) => handleChange(e)}
            id="flexRadioDefault1"
          />
          <label className="form-check-label" htmlFor="flexRadioDefault1">
            FISCALIZACIÓN Y SUPERVISIÓN
          </label>
        </div>
        {flgSelect === 2 && (
          <div className="mt-1 mb-3 form-group">
            <select onChange={(e) => onSelect(e)} className="form-control">
              <option value="-">Elija La Alerta</option>

              <option value="Distribución Del Medio Ambiente">
                Distribución Del Medio Ambiente
              </option>
              <option value="Desviaciones Operativas">
                Desviaciones Operativas
              </option>
              <option value="Contaminación">Contaminación</option>
              <option value="Obligaciones">Obligaciones</option>
              <option value="Capacidad De Respuesta">
                Capacidad De Respuesta
              </option>
            </select>
          </div>
        )}
        <div className="form-check">
          <input
            className="form-check-input circuloNaranja"
            type="checkbox"
            name="DAMSAFETY"
            onChange={(e) => handleChange(e)}
            checked={flgNivelIncidente[2]}
            id="flexRadioDefault2"
          />
          <label className="form-check-label" htmlFor="flexRadioDefault2">
            DAM SAFETY
          </label>
        </div>
        {flgSelect === 3 && (
          <div className="mt-1 mb-3 form-group">
            <select onChange={(e) => onSelect(e)} className="form-control">
              <option value="-">Elija La Alerta</option>

              <option value="Daños A La Infraestructura">
                Daños A La Infraestructura
              </option>
              <option value="Conflictos Sociales">Conflictos Sociales</option>
              <option value="Inestabilidad Física De La Presa">
                Inestabilidad Física De La Presa
              </option>
              <option value="Capacidad De Respuesta Inmediata">
                Capacidad De Respuesta Inmediata
              </option>
            </select>
          </div>
        )}
      </div>
      {textoPlazo !== "" && (
        <div className="alert alert-warning" role="alert">
          {textoPlazo}
        </div>
      )}
      <div className="form-floating">
        <div className="mt-1 mb-3 form-group">
          <select
            id="flexRadioDefault223"
            name="nivelcat"
            onChange={(e) => setnivelCat(e.target.value)}
            className="form-control"
          >
            <option value="-">Elija El Nivel CAT De La Alerta</option>

            <option value="cat0" disabled={flgCat[0]}>CAT 0 - Cuasi-accidente</option>
            <option value="cat1" disabled={flgCat[1]}>CAT 1 - Insignificante</option>
            <option value="cat2" disabled={flgCat[2]}>CAT 2 - Menor</option>
            <option value="cat3" disabled={flgCat[3]}>CAT 3 - Moderada</option>
            <option value="cat4" disabled={flgCat[4]}>CAT 4 - Mayor</option>
            <option value="cat5" disabled={flgCat[5]}>CAT 5 - Catastrófica</option>
          </select>
        </div>
      </div>
      <div className="form-floating">
        <textarea
          className="form-control"
          style={{ height: "100px" }}
          onChange={(e) => setdescripcionIncidente(e.target.value)}
          placeholder="Ingrese la descripción de la observación"
          id="floatingTextarea"
        ></textarea>
        <label htmlFor="floatingTextarea">Describa la observación</label>
      </div>
      <div className="form-floating">
        {message.msg ? (
          <MessageAlert msg={message.msg} rol={message.rol} />
        ) : null}
        <input
          className="form-control custom-file-input"
          type="file"
          lang="es"
          onChange={onChangeFile}
          accept="*"
          capture
        />
        <label htmlFor="floatingTextarea">{filename}</label>
      </div>
      {archivoVal && (
        <div className="form-floating">
          <ProgressBar per={uploadPer} />
        </div>
      )}
      <div className="form-floating">
        {flgEnviando && (
          <div className="alert alert-info d-flex " role="alert">
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border m-3"
                role="status"
                aria-hidden="true"
              ></div>
              <strong className="m-3">Enviando Reporte...</strong>
            </div>
          </div>
        )}
      </div>
      {flgButton ? (
        <button
          className="w-100 btn btn-lg botonIngesar"
          onClick={handleSubmit}
          type="button"
        >
          Ingresar
        </button>
      ) : (
        <button
          className="w-100 btn btn-lg botonIngesar"
          disabled
          type="button"
        >
          Ingresar
        </button>
      )}
    </>
  ) : (
    <Response flgEnvioOk={flgEnvioOk} />
  );
};

export default Formulario;
