import { useEffect, useState, useCallback } from "react";
import {
  getReportesAbiertos,
  getReporte,
  subirArchivo,
  putReporte
} from "./shared/functions/services";
import ProgressBar from "./shared/Components/ProgressBar";
import MessageAlert from "./shared/Components/MessageAlert";
import Response from "./Response";

const CerrarReport = ({ setVista }) => {
  const [reportesA, setreportesA] = useState([]);
  const [idRElec, setidRElec] = useState("");
  const [reporteE, setreporteE] = useState([]);
  const [uploadPer, setuploadPer] = useState(0);
  const [file, setfile] = useState("");
  const [filename, setfilename] = useState("Elija El Archivo");
  const [archivoVal, setarchivoVal] = useState(false);
  const [tipoFile, settipoFile] = useState("");
  const [message, setmessage] = useState({ msg: "", rol: "" });
  const [flg_R, setflg_R] = useState(false);
  const [flgEnviando, setflgEnviando] = useState(false);

  const [flgEnvioOk, setflgEnvioOk] = useState(false);
  const [flgResponse, setflgResponse] = useState(false);

  useEffect(() => {
    try {
      const uD = Promise.all([getReportesAbiertos()]);
      uD.then((res) => {
        setreportesA(res[0].reportes);
        //console.log(res[0].reportes)
      }).catch((err) => {
        console.log(err);
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    try {
      if (idRElec !== "") {
        const uD = Promise.all([getReporte(idRElec)]);
        uD.then((res) => {
          setreporteE(res[0].reporte);
          setflg_R(true);
          console.log(res[0].reporte);
        }).catch((err) => {
          console.log(err);
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [idRElec]);

  const onChangeFile = (e) => {
    if (e.target.files[0] !== undefined) {
      setuploadPer(0);
      setfile(e.target.files[0]);
      setfilename(e.target.files[0].name);
      const exten = e.target.files[0].name.split(".");
      const extenL = exten.length;
      if (
        exten[extenL - 1] === "jpg" ||
        exten[extenL - 1] === "jpeg" ||
        exten[extenL - 1] === "JPEG" ||
        exten[extenL - 1] === "png" ||
        exten[extenL - 1] === "JPG" ||
        exten[extenL - 1] === "PNG"
      ) {
        setmessage({
          msg: "",
          rol: ""
        });
        setarchivoVal(true);
        settipoFile("img");
      } else if (exten[extenL - 1] === "pdf"||exten[extenL - 1] === "PDF") {
        setarchivoVal(true);
        settipoFile("pdf");
        setmessage({
          msg: "",
          rol: ""
        });
      } else if (exten[extenL - 1] === "zip"||exten[extenL - 1] === "ZIP") {
        setarchivoVal(true);
        settipoFile("zip");
        setmessage({
          msg: "",
          rol: ""
        });
      } else if (exten[extenL - 1] === "rar"||exten[extenL - 1] === "RAR") {
        setarchivoVal(true);
        settipoFile("rar");
        setmessage({
          msg: "",
          rol: ""
        });
      } else {
        setarchivoVal(false);
        setmessage({
          msg: "El Archivo Debe Ser pdf, PDF, rar, RAR, zip, ZIP, jpg, JPG, JPEG, jpeg, png o PNG.",
          rol: "warning"
        });
      }
    }
  };

  const handleSubmit = useCallback(async () => {
    try {
      setflgEnviando(true);

      var repDes = reporteE[0].descripcion[0];
      //console.log(repDes)
      if (archivoVal) {
        let dFile = await subirArchivo(file, setuploadPer);
        if (dFile.success) {
          repDes.fileList = [
            ...repDes.fileList,
            { ...dFile.data, tipo: tipoFile }
          ];
        }
      }
      // console.log(repDes)
      const uR = Promise.all([putReporte(idRElec, repDes)]);
      uR.then((res) => {
        //console.log(res[0]);
        if (res[0].success) {
          setflgEnviando(false);
          setflgEnvioOk(true);
          setflgResponse(true);
        } else {
          //console.log("error en post Reporte 1")

          setflgEnviando(false);
          setflgEnvioOk(false);
          setflgResponse(true);
        }
      }).catch((err) => {
        console.log(err);
        setflgEnviando(false);
        setflgEnvioOk(false);
        setflgResponse(true);
      });
    } catch (err) {
      console.log(err);
    }
  }, [file, setuploadPer, tipoFile, archivoVal, reporteE, idRElec]);

  return !flgResponse ? (
    <>
      <button
        type="button"
        onClick={() => setVista("menu")}
        className="btn btn-link"
      >
        Regresar Al Menú
      </button>
      <h6 className="mb-3 fw-normal titulo">
        Busque en la lista el reporte que desea cerrar
      </h6>
      <div className="form-floating">
        <select
          onChange={(e) => setidRElec(e.target.value)}
          className="form-control form-signin-select"
          id="reportesa"
        >
          <option defaultValue={""} value="" className="item-s">
            Elija el reporte a cerrar
          </option>

          {reportesA.map((rA, index) => {
            return (
              <option key={index} value={rA._id} className="item-s">
                {`${rA.fechaReporte} - ${rA.ubicacionN}`}
              </option>
            );
          })}
        </select>
        <label htmlFor="sitios">Reportes Abiertos</label>
      </div>
      {flg_R && (
        <>
          <div className="form-floating">
            <textarea
              className="form-control"
              style={{ height: "100px" }}
              defaultValue={reporteE[0].descripcion[0].descripcion}
              readOnly
            ></textarea>
            <label htmlFor="floatingTextarea">Descripción del reporte</label>
          </div>
          <div className="form-floating">
            {message.msg ? (
              <MessageAlert msg={message.msg} rol={message.rol} />
            ) : null}
            <input
              className="form-control custom-file-input"
              type="file"
              lang="es"
              onChange={onChangeFile}
              accept="*"
              capture
            />
            <label htmlFor="floatingTextarea">{filename}</label>
          </div>
          <div className="form-floating">
            {flgEnviando && (
              <div className="alert alert-info d-flex " role="alert">
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border m-3"
                    role="status"
                    aria-hidden="true"
                  ></div>
                  <strong className="m-3">Enviando Reporte...</strong>
                </div>
              </div>
            )}
          </div>
          {archivoVal && (
            <div className="form-floating">
              <ProgressBar per={uploadPer} />
            </div>
          )}
          <button
            className="w-100 btn btn-lg botonIngesar"
            onClick={handleSubmit}
            type="button"
          >
            Cerrar Este Reporte
          </button>
        </>
      )}
    </>
  ) : (
    <Response flgEnvioOk={flgEnvioOk} />
  );
};

export default CerrarReport;
