import { useState } from "react";
import CerrarReport from "./CerrarReport";
import Formulario from "./Formulario";
import { destroyStorage } from "./shared/functions/services";

const MenuVista = () => {
  const [Vista, setVista] = useState("menu");

  const cerrarVentana = () => {
    if (destroyStorage()) {
      window.location.href = "/";
    }
  };

  return (
    <>
      {Vista === "menu" && (
        <>
          <h6 className="mb-3 fw-normal titulo">
            Por favor elija lo que desea hacer
          </h6>

          <button
            className="w-100 btn btn-lg botonIngesar mb-3"
            onClick={() => setVista("formulario")}
            type="button"
          >
            Crear Reporte
          </button>

          <button
            className="w-100 btn btn-lg botonIngesar"
            onClick={() => setVista("cerrar")}
            type="button"
          >
            Cerrar Reporte
          </button>
        </>
      )}
      {Vista === "formulario" && <Formulario setVista={setVista} />}
      {Vista === "cerrar" && <CerrarReport setVista={setVista} />}
      {Vista === "responsible" && <>Responsable</>}
      <button type="button" onClick={cerrarVentana} className="btn btn-link">
        Salir
      </button>
    </>
  );
};

export default MenuVista;
