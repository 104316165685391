import { useState, useEffect } from "react";
import logo from "./pages/shared/images/logoblanco.png";
import Login from "./pages/Login";
import {
  isAutenticado,
  obtenerParametrosDeConsulta
} from "./pages/shared/functions/services";
import MenuVista from "./pages/MenuVista";

function App() {
  const [isAuth, setisAuth] = useState(false);
  const [menuT, setmenuT] = useState("");
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    const urlL = window.location.href.split("/");

    if (urlL.length > 4) {
      setmenuT(urlL[3]);
      setQueryParams(obtenerParametrosDeConsulta());
    } else {
      setisAuth(isAutenticado());
    }
  }, []);

  return (
    <div className="bo text-center">
      <main className="form-signin w-100 m-auto">
        <form>
          <img
            className="mb-4"
            src={logo}
            alt="logo Antapaccay"
            height="87"
          ></img>
          <h5>Gerencia de Recursos Hídricos</h5>
          <h5>Superintendencia Dam Safety</h5>
          {isAuth ? (
            <MenuVista />
          ) : (
            <Login
              setisAuth={setisAuth}
              menuT={menuT}
              queryParams={queryParams}
            />
          )}
        </form>
      </main>
    </div>
  );
}

export default App;
